import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Link } from 'gatsby';
import { capitalize, SM, MD } from './global';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import HomeIcon from '@material-ui/icons/Home';
import FaceIcon from '@material-ui/icons/Face';
import WorkIcon from '@material-ui/icons/Work';
// import ChatIcon from '@material-ui/icons/ChatBubble';
import blue from '@material-ui/core/colors/blue';
import styled from 'styled-components';

const navItems = [
  { name: 'home', icon: <HomeIcon /> },
  { name: 'about', icon: <FaceIcon /> },
  { name: 'portfolio', icon: <WorkIcon /> },
  // { name: 'contact', icon: <ChatIcon /> },
];

const StyledLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  padding: 8px 0;
  display: inline-block;
`;
export class NavBar extends React.Component {
  state = {
    value: 0,
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { title } = this.props;
    return (
      <AppBar position="fixed" color="default">
        <Toolbar>
          <Grid container spacing={0} alignItems="center">
            <Grid item xs={12} md={4} xl={3} style={{ textAlign: 'left' }}>
              <Typography variant="subheading" color="inherit">
                <StyledLink to="/">{title}</StyledLink>
              </Typography>
            </Grid>
            <Grid item xs={12} md={8} xl={9} style={{ textAlign: 'right' }}>
              <MD>
                {navItems.map((navItem) => (
                  <Button key={navItem.name} href={'/#' + navItem.name}>
                    {capitalize(navItem.name)}
                  </Button>
                ))}
              </MD>

              <SM>
                <Tabs
                  value={this.state.value}
                  onChange={this.handleChange}
                  fullWidth
                  indicatorColor={blue[200]}
                  textColor="secondary"
                >
                  {navItems.map((navItem) => (
                    <Tab
                      key={navItem.name}
                      icon={navItem.icon}
                      href={'/#' + navItem.name}
                    />
                  ))}
                </Tabs>
              </SM>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  }
}
