import React from 'react';
import styled from 'styled-components';
import Responsive from 'react-responsive';

export const urlMask = (url) => {
  return url.replace('s3-ap-southeast-1.amazonaws.com/', '');
};

export const portfoUrl = (url) => {
  return url.toLowerCase().replace(/ /g, '-');
};

export const capitalize = function(stringToCap) {
  return stringToCap.charAt(0).toUpperCase() + stringToCap.slice(1);
};

export const SM = (props) => <Responsive {...props} maxWidth={959} />;
export const MD = (props) => <Responsive {...props} minWidth={960} />;

export const StyledLink = styled.a`
  color: #fff;
  text-decoration: none;

  :focus {
    outline: none;
  }
`;

export const ModularRow = styled.section`
  padding: 5em 16px 2em;
  display: flex;
  min-height: 100vh;
  position: relative;
  flex-direction: column;

  @media (min-width: 960px) {
    padding: 5em 10vw 2em;
  }
`;

export const ModularRowPortfo = styled(ModularRow)`
  align-items: flex-start;
`;

export const ModularRowContact = styled(ModularRow)`
  min-height: 70vh;
`;

export const ModularRowAbout = styled(ModularRow)`
  justify-content: center;
`;

export const PortfoWrapper = styled.div`
  max-width: 850px;
  margin: 100px auto 0;
  padding: 0 16px;

  img {
    max-width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }

  a {
    color: #74c9e2;
  }
`;
