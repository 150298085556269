import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

import './layout.css';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import myTheme from '../components/theme';
import Favicon from 'react-favicon';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Transition } from '../components/transition';
import { NavBar } from '../components/navBar';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  margin: 0;
  padding: 0;
  font-family: 'Fira Sans', sans-serif;
  background: #303030;
  color: #fff;

  iframe {
    max-width: 100% !important;
  }
`;

const Layout = ({ children, location }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <MuiThemeProvider theme={myTheme}>
        <StyledWrapper>
          <Favicon url="//s3.kennyleung.design/favico.png" />
          <CssBaseline />
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              { name: 'description', content: 'Kenny Leung, UX Engineer in Hong Kong' },
              { name: 'keywords', content: 'UX, Design, Code' },
            ]}
          >
            <html lang="en" />
          </Helmet>
          <NavBar title={data.site.siteMetadata.title} />
          <div>
            <Transition location={location}>{children}</Transition>
          </div>
        </StyledWrapper>
      </MuiThemeProvider>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
